import React, { useContext, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import { AuthContext } from "../../context/AuthProvider";
import { useTranslation } from "react-i18next";

import logo from "./logo3.png";
const api_url = process.env.REACT_APP_API_URL;

export const NavBar = () => {
  const context = useContext(AuthContext);
  const { t, i18n } = useTranslation("global");
  const navigate = useNavigate();

  const logout = (event) => {
    event.preventDefault();

    context.setIsAuth(false);
    context.setUser({});
    context.setToken("");
    localStorage.removeItem("token");

    navigate("/login");
  };

  useEffect(() => {
    if (!context.isAuth) {
      if (localStorage.getItem("token")) {
        fetch(api_url + "/user", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
          .then((res) => {
            if (res.status !== 200) {
              localStorage.removeItem("token");
              context.setIsAuth(false);
              context.setUser({});
              context.setToken("");
            } else {
              return res.json();
            }
          })
          .then((data) => {
            context.setUser(data.user);
            context.setIsAuth(true);
            context.setToken(localStorage.getItem("token"));
          });
      }
    }
  }, [context]);

  return (
    <>
      <nav className="navbar sticky-top navbar-expand-md navbar-dark bg-dark">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="logo" height="40" className="" />
          </Link>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link className="nav-link" to="/">
                  {t("navbar.home")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/docs">
                  {t("navbar.docs")}
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/device/config">
                  {t("navbar.device_configuration")}
                </Link>
              </li>
              {context.isAuth && (
                <li className="nav-item">
                  <Link className="nav-link" to="/devices">
                    {t("navbar.devices")}
                  </Link>
                </li>
              )}
            </ul>
          </div>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a
                  href="!#"
                  className="nav-link dropdown-toggle"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {t("navbar.language")}
                </a>
                <ul
                  className="dropdown-menu dropdown-menu-dark"
                  aria-labelledby="navbarDarkDropdownMenuLink">
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => i18n.changeLanguage("cs")}>
                      Čeština
                      <i className="flag-icon flag-icon-cz"></i>
                    </button>
                  </li>
                  <li>
                    <button
                      className="dropdown-item"
                      onClick={() => i18n.changeLanguage("en")}>
                      English
                    </button>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <ul className="navbar-nav">
            {context.isAuth ? (
              <>
                <li className="nav-item dropdown">
                  <a
                    href="!#"
                    className="nav-link dropdown-toggle"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    {t("navbar.logged_as")}
                    {": "} {context.user.nickname}
                  </a>
                  <ul
                    className="dropdown-menu dropdown-menu-dark"
                    aria-labelledby="navbarDarkDropdownMenuLink">
                    <li>
                      <Link className="dropdown-item" to="/devices">
                        {t("navbar.devices")}
                      </Link>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={logout}>
                        {t("navbar.logout")}
                      </button>
                    </li>
                  </ul>
                </li>
              </>
            ) : (
              <>
                <Link to="/login" className="btn btn-outline-light">
                  {t("navbar.login")}
                </Link>
              </>
            )}
          </ul>
        </div>
      </nav>
      <div class="alert alert-warning" role="alert">
        Stránka se předělává, může dojít k chybám. Děkujeme za pochopení.
        Nastavení bojlerů je zatím nefunkční. Přidávají se nové funkce, ale
        bojlery jsou funkční s posledním nastavením. V případě nutnosti změny
        nastavení kotaktujte mě na email: petrasek@solarbeam.cz .
      </div>
    </>
  );
};
