import React, { useState } from "react";
import "./settings.css";
//import { putFetcher } from "../fetcher";

import { useTranslation } from "react-i18next";
import Switch from "../Settings/switch";

export const SLCSettings = (props) => {
  const { t } = useTranslation("global");
  console.log("SLCSettings", props);

  //time window 1

  const [time_window_1_enabled, set_time_window_1_enabled] = useState(
    props.device?.config?.time_window_1?.enabled
      ? props.device.config.time_window_1.enabled
      : props.device.config.time_control
  );
  const [time_window_1_start_time, set_time_window_1_start_time] = useState(
    props.device?.config?.time_window_1?.start_time
      ? props.device.config.time_window_1.start_time
      : props.device.config.time_start
  );
  const [time_window_1_end_time, set_time_window_1_end_time] = useState(
    props.device?.config?.time_window_1?.end_time
      ? props.device.config.time_window_1.end_time
      : props.device.config.time_end
  );
  const [time_window_1_temperature, set_time_window_1_temperature] = useState(
    props.device?.config?.time_window_1?.temperature
      ? props.device.config.time_window_1.temperature
      : props.device.config.max_temp
  );

  //time window 2

  const [time_window_2_enabled, set_time_window_2_enabled] = useState(
    props.device?.config?.time_window_2?.enabled
      ? props.device.config.time_window_2.enabled
      : false
  );
  const [time_window_2_start_time, set_time_window_2_start_time] = useState(
    props.device?.config?.time_window_2?.start_time
      ? props.device.config.time_window_2.start_time
      : "07:00"
  );
  const [time_window_2_end_time, set_time_window_2_end_time] = useState(
    props.device?.config?.time_window_2?.end_time
      ? props.device.config.time_window_2.end_time
      : "17:00"
  );
  const [time_window_2_temperature, set_time_window_2_temperature] = useState(
    props.device?.config?.time_window_2?.temperature
      ? props.device.config.time_window_2.temperature
      : 55
  );

  //time window 3

  const [time_window_3_enabled, set_time_window_3_enabled] = useState(
    props.device?.config?.time_window_3?.enabled
      ? props.device.config.time_window_3.enabled
      : false
  );
  const [time_window_3_start_time, set_time_window_3_start_time] = useState(
    props.device?.config?.time_window_3?.start_time
      ? props.device.config.time_window_3.start_time
      : "07:00"
  );
  const [time_window_3_end_time, set_time_window_3_end_time] = useState(
    props.device?.config?.time_window_3?.end_time
      ? props.device.config.time_window_3.end_time
      : "17:00"
  );
  const [time_window_3_temperature, set_time_window_3_temperature] = useState(
    props.device?.config?.time_window_3?.temperature
      ? props.device.config.time_window_3.temperature
      : 55
  );

  //time window 4

  const [time_window_4_enabled, set_time_window_4_enabled] = useState(
    props.device?.config?.time_window_4?.enabled
      ? props.device.config.time_window_4.enabled
      : false
  );
  const [time_window_4_start_time, set_time_window_4_start_time] = useState(
    props.device?.config?.time_window_4?.start_time
      ? props.device.config.time_window_4.start_time
      : "07:00"
  );
  const [time_window_4_end_time, set_time_window_4_end_time] = useState(
    props.device?.config?.time_window_4?.end_time
      ? props.device.config.time_window_4.end_time
      : "17:00"
  );
  const [time_window_4_temperature, set_time_window_4_temperature] = useState(
    props.device?.config?.time_window_4?.temperature
      ? props.device.config.time_window_4.temperature
      : 55
  );

  //time window 5

  const [time_window_5_enabled, set_time_window_5_enabled] = useState(
    props.device?.config?.time_window_5?.enabled
      ? props.device.config.time_window_5.enabled
      : false
  );
  const [time_window_5_start_time, set_time_window_5_start_time] = useState(
    props.device?.config?.time_window_5?.start_time
      ? props.device.config.time_window_5.start_time
      : "07:00"
  );
  const [time_window_5_end_time, set_time_window_5_end_time] = useState(
    props.device?.config?.time_window_5?.end_time
      ? props.device.config.time_window_5.end_time
      : "17:00"
  );
  const [time_window_5_temperature, set_time_window_5_temperature] = useState(
    props.device?.config?.time_window_5?.temperature
      ? props.device.config.time_window_5.temperature
      : 55
  );

  //battery control

  const [battery_control, set_battery_control] = useState(
    props.device?.config?.battery_control?.enabled
      ? props.device.config.battery_control.enabled
      : props.device.config.battery_control
  );
  const [min_battery, set_min_battery] = useState(
    props.device?.config?.battery_control?.min_battery
      ? props.device.config.battery_control.min_battery
      : props.device.config.min_battery
  );
  const [max_battery, set_max_battery] = useState(
    props.device?.config?.battery_control?.max_battery
      ? props.device.config.battery_control.max_battery
      : props.device.config.max_battery
  );
  const [battery_temperature, set_battery_temperature] = useState(
    props.device?.config?.battery_control?.temperature
      ? props.device.config.battery_control.temperature
      : props.device.config.max_heat
  );

  //export control

  const [export_control, set_export_control] = useState(
    props.device?.config?.export_control?.enabled
      ? props.device.config.export_control.enabled
      : props.device.config.export_control
  );
  const [export_power, set_export_power] = useState(
    props.device?.config?.export_control?.power
      ? props.device.config.export_control.power
      : props.device.config.power
  );
  const [export_temperature, set_export_temperature] = useState(
    props.device?.config?.export_control?.temperature
      ? props.device.config.export_control.temperature
      : props.device.config.max_heat
  );

  const handle_time_window_1_enabled = (e) => {
    set_time_window_1_enabled(!time_window_1_enabled);
  };

  const handle_time_window_1_start_time = (e) => {
    e.preventDefault();
    set_time_window_1_start_time(e.target.value);
  };

  const handle_time_window_1_end_time = (e) => {
    e.preventDefault();
    set_time_window_1_end_time(e.target.value);
  };

  const handle_time_window_1_temperature = (e) => {
    e.preventDefault();
    set_time_window_1_temperature(e.target.value);
  };

  const handle_time_window_2_enabled = (e) => {
    set_time_window_2_enabled(!time_window_2_enabled);
  };

  const handle_time_window_2_start_time = (e) => {
    e.preventDefault();
    set_time_window_2_start_time(e.target.value);
  };

  const handle_time_window_2_end_time = (e) => {
    e.preventDefault();
    set_time_window_2_end_time(e.target.value);
  };

  const handle_time_window_2_temperature = (e) => {
    e.preventDefault();
    set_time_window_2_temperature(e.target.value);
  };

  const handle_time_window_3_enabled = (e) => {
    set_time_window_3_enabled(!time_window_3_enabled);
  };

  const handle_time_window_3_start_time = (e) => {
    e.preventDefault();
    set_time_window_3_start_time(e.target.value);
  };

  const handle_time_window_3_end_time = (e) => {
    e.preventDefault();
    set_time_window_3_end_time(e.target.value);
  };

  const handle_time_window_3_temperature = (e) => {
    e.preventDefault();
    set_time_window_3_temperature(e.target.value);
  };

  const handle_time_window_4_enabled = (e) => {
    set_time_window_4_enabled(!time_window_4_enabled);
  };

  const handle_time_window_4_start_time = (e) => {
    e.preventDefault();
    set_time_window_4_start_time(e.target.value);
  };

  const handle_time_window_4_end_time = (e) => {
    e.preventDefault();
    set_time_window_4_end_time(e.target.value);
  };

  const handle_time_window_4_temperature = (e) => {
    e.preventDefault();
    set_time_window_4_temperature(e.target.value);
  };

  const handle_time_window_5_enabled = (e) => {
    set_time_window_5_enabled(!time_window_5_enabled);
  };

  const handle_time_window_5_start_time = (e) => {
    e.preventDefault();
    set_time_window_5_start_time(e.target.value);
  };

  const handle_time_window_5_end_time = (e) => {
    e.preventDefault();
    set_time_window_5_end_time(e.target.value);
  };

  const handle_time_window_5_temperature = (e) => {
    e.preventDefault();
    set_time_window_5_temperature(e.target.value);
  };

  const handle_battery_control = (e) => {
    set_battery_control(!battery_control);
  };

  const handle_min_battery = (e) => {
    e.preventDefault();
    set_min_battery(e.target.value);
  };

  const handle_max_battery = (e) => {
    e.preventDefault();
    set_max_battery(e.target.value);
  };

  const handle_battery_temperature = (e) => {
    e.preventDefault();
    set_battery_temperature(e.target.value);
  };

  const handle_export_control = (e) => {
    set_export_control(!export_control);
  };

  const handle_export_power = (e) => {
    e.preventDefault();
    set_export_power(e.target.value);
  };

  const handle_export_temperature = (e) => {
    e.preventDefault();
    set_export_temperature(e.target.value);
  };

  const handleSave = () => {
    /*
    putFetcher("/device/config/" + props.device._id, {
      min_temp: minTemp === undefined ? 50 : minTemp,
      max_temp: maxTemp === undefined ? 55 : maxTemp,
      max_heat: maxHeat === undefined ? 75 : maxHeat,
      time_end: time_end === undefined ? "17:00" : time_end,
      time_start: time_start === undefined ? "15:00" : time_start,
      time_control: time_control === undefined ? false : time_control,
      battery_control: battery_control === undefined ? false : battery_control,
      min_battery: min_battery === undefined ? 95 : min_battery,
      max_battery: max_battery === undefined ? 98 : max_battery,
      power: export_power === undefined ? 2000 : export_power,
      export_control: export_control === undefined ? false : export_control,
    }).then((data) => {
      console.log("putFetcher", data);
    });*/
    console.log("save");
  };

  return (
    <>
      <div className="container-fluid">
        <h1 className="h3 mb-2 text-gray-800">{t("slc_settings.title")}</h1>
        <div class="accordion" id="accordionPanelsStayOpenExample">
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingZero">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseZero"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseZero">
                {t("slc_settings.system_deactivate")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseZero"
              class="accordion-collapse collapse show">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_1_enabled}
                      onToggle={handle_time_window_1_enabled}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingOne">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseOne"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseOne">
                {t("slc_settings.time_window_1")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseOne"
              class="accordion-collapse collapse show">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_1_enabled}
                      onToggle={handle_time_window_1_enabled}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_window_1_start_time}
                        onChange={handle_time_window_1_start_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_window_1_end_time}
                        onChange={handle_time_window_1_end_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={time_window_1_temperature}
                        onChange={handle_time_window_1_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseTwo"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseTwo">
                {t("slc_settings.time_window_2")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseTwo"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_2_enabled}
                      onToggle={handle_time_window_2_enabled}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_window_2_start_time}
                        onChange={handle_time_window_2_start_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_window_2_end_time}
                        onChange={handle_time_window_2_end_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={time_window_2_temperature}
                        onChange={handle_time_window_2_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingthree">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseThree"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseThree">
                {t("slc_settings.time_window_3")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseThree"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_3_enabled}
                      onToggle={handle_time_window_3_enabled}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_window_3_start_time}
                        onChange={handle_time_window_3_start_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_window_3_end_time}
                        onChange={handle_time_window_3_end_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={time_window_3_temperature}
                        onChange={handle_time_window_3_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFour">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFour"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFour">
                {t("slc_settings.time_window_4")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseFour"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_4_enabled}
                      onToggle={handle_time_window_4_enabled}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_window_4_start_time}
                        onChange={handle_time_window_4_start_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_window_4_end_time}
                        onChange={handle_time_window_4_end_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={time_window_4_temperature}
                        onChange={handle_time_window_4_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingFive">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFive">
                {t("slc_settings.time_window_5")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseFive"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={time_window_5_enabled}
                      onToggle={handle_time_window_5_enabled}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.start_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="Start time"
                        aria-label="Start time"
                        aria-describedby="basic-addon1"
                        value={time_window_5_start_time}
                        onChange={handle_time_window_5_start_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.end_time")}
                      </span>
                      <input
                        type="time"
                        class="form-control"
                        placeholder="End time"
                        aria-label="End time"
                        aria-describedby="basic-addon1"
                        value={time_window_5_end_time}
                        onChange={handle_time_window_5_end_time}
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={time_window_5_temperature}
                        onChange={handle_time_window_5_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSix">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSix"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSix">
                {t("slc_settings.export_control")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseSix"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.export_control")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={export_control}
                      onToggle={handle_export_control}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.export_power")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Export power"
                        aria-label="Export power"
                        aria-describedby="basic-addon1"
                        value={export_power}
                        onChange={handle_export_power}
                      />
                      <span class="input-group-text">W</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={export_temperature}
                        onChange={handle_export_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="panelsStayOpen-headingSeven">
              <button
                class="accordion-button"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseSeven"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseSeven">
                {t("slc_settings.battery_control")}
              </button>
            </h2>

            <div
              id="panelsStayOpen-collapseSeven"
              class="accordion-collapse collapse">
              <div class="accordion-body">
                <div className="row">
                  <div className=" col-md-4">
                    <div class="input-group mb-3">
                      <span class="input-group-text" id="basic-addon1">
                        {t("slc_settings.enabled")}
                      </span>
                    </div>
                  </div>
                  {/*<div className="col-md-6"></div>*/}
                  <div className="col-md-2">
                    <Switch
                      isToggled={battery_control}
                      onToggle={handle_battery_control}
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.min_battery")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="min battery"
                        aria-label="min battery"
                        aria-describedby="basic-addon1"
                        value={min_battery}
                        onChange={handle_min_battery}
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.max_battery")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="max battery"
                        aria-label="max battery"
                        aria-describedby="basic-addon1"
                        value={max_battery}
                        onChange={handle_max_battery}
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6">
                    <div class="input-group mb-3">
                      <span class="input-group-text col-6" id="basic-addon1">
                        {t("slc_settings.temperature")}
                      </span>
                      <input
                        type="number"
                        class="form-control"
                        placeholder="Max temperature"
                        aria-label="Max temperature"
                        aria-describedby="basic-addon1"
                        value={battery_temperature}
                        onChange={handle_battery_temperature}
                      />
                      <span class="input-group-text">°C</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-1 ms-auto">
              <button
                type="button"
                class="btn btn-success"
                onClick={handleSave}>
                {t("slc_settings.save")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
